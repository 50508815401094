import { Avatar, Box, CardActionArea, Container, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStateContext } from '../core/ApplicationContext'
import { usePlayerStateContext, usePlayerUpdaterContext, usePlaylistUpdaterContext } from '../core/PlayerContext'
import playlistReducer, { APPEND } from '../core/reducer/playlistReducer'
import AppTheme from '../core/AppTheme'

export default function SpotifyPlaylistSelector() {

  const user = useUserStateContext()
  const playlistCtx = usePlayerStateContext()
  const playlistUpdateCtx = usePlayerUpdaterContext()
  const [playlists, setPlaylists] = useState({})
  const navigate = useNavigate()
  const [selectedPlaylist, setSelectedPlaylist] = useState(false)

  useEffect(() => {
    selectedPlaylist && navigate('/')
  }, [selectedPlaylist])

  useEffect(() => {
    fetch('https://api.spotify.com/v1/me/playlists', { method: 'GET', headers: { 'Authorization': `Bearer ${user.token}` } })
      .then(response => response.json())
      .then(json => setPlaylists(json))
  }, [])

  return (
    <Container maxWidth='lg' disableGutters>
      {playlists.items &&
        playlists.items.map((item, key) =>
          <Playlist key={key} item={item} />
        )}
    </Container>
  )

  function clickedPlaylist(event, id) {
    fetch(`https://api.spotify.com/v1/playlists/${id}`, { method: 'GET', headers: { 'Authorization': `Bearer ${user.token}` } })
      .then(response => response.json())
      .then(json => playlistReducer(playlistCtx, { type: APPEND, tracks: json.tracks }))
      .then(reduced => playlistUpdateCtx(reduced))
      .then(setSelectedPlaylist(true))
  }

  function Playlist({ item }) {
    return (
      <CardActionArea
        onClick={(event) => clickedPlaylist(event, item.id)}>
        <Grid pt={1}>
          <Grid container
            style={AppTheme.grid.playlist}
            pt={1}
            flexGrow={1}
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'>
            <Grid item
              xs='auto'
              margin='auto' >
              <Avatar src={item.images[item.images.length - 1]?.url} sx={{ width: 64, height: 64, }} variant='square' />
            </Grid>
            <Grid item
              md={10.8}
              xs={9.5}
              flexGrow={1}
              sx={{ overflow: 'hidden' }}
              p={1} >
              <Box sx={{ typography: 'h6', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                {item.name}
              </Box>
              <Box item sx={{ typography: 'body1', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                noWrap
                style={AppTheme.palette.artist} >
                By {item.owner.display_name}, {item.tracks.total} tracks
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea >
    )
  }
}
