import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ApplicationContextProvider, useUserStateContext } from './core/ApplicationContext'
import { PlayerContextProvider } from './core/PlayerContext'
import { ControlContextProvider } from './core/ControlContext'
import Callback from './spotify/Callback'
import SpotifyLogin from './spotify/SpotifyLogin'
import Player from './player/Player'
import StartPage from './core/StartPage'
import AppTheme from './core/AppTheme'
import { CssBaseline, ThemeProvider } from '@mui/material'
import './hockify.css'

function Hockify() {
  return (
    <ApplicationContextProvider>
      <ControlContextProvider>
        <PlayerContextProvider>
          <ThemeProvider theme={AppTheme}>
          <CssBaseline />
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<Home />} />
                <Route path="spotify/login" element={<SpotifyLogin />} />
                <Route path="spotify/callback/*" element={<Callback />} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </PlayerContextProvider>
      </ControlContextProvider>
    </ApplicationContextProvider>
  )
}

function Home() {
  const user = useUserStateContext()
  return user.token == null || user.expires < Date.now() ? <StartPage /> : <Player />
}

export default Hockify