import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Divider, IconButton, ListItemIcon, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import LogoutIcon from '@mui/icons-material/Logout'
import SpotifyLogout from '../spotify/SpotifyLogout'

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const goHome = () => {
    navigate('/')
    handleClose()
  }
  const goPlaylists = () => {
    navigate('/playlists')
    handleClose()
  }
  const goRemoveTracks = () => {
    navigate('/removealltracks')
    handleClose()
  }
  const goDownload = () => {
    navigate('/download')
    handleClose()
  }
  const goUpload = () => {
    navigate('/upload')
    handleClose()
  }
  const goCleanList = () => {
    navigate('/clean')
    handleClose()
  }
  const goLogout = () => {
    handleClose()
  }

  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        id="menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={goHome}><ListItemIcon><HomeIcon /></ListItemIcon> Home</MenuItem>
        <Divider />
        <T title='Drag more song in from a playlist!'>
          <MenuItem onClick={goPlaylists}><ListItemIcon><PlaylistAddIcon /></ListItemIcon> Append Playlist</MenuItem>
        </T>
        <T title='Remove everything and start over!'>
          <MenuItem onClick={goRemoveTracks}><ListItemIcon><PlaylistRemoveIcon /></ListItemIcon> Remove All Tracks</MenuItem>
        </T>
        <T title='Refresh the playlist'>
          <MenuItem onClick={goCleanList}><ListItemIcon><RestartAltIcon /></ListItemIcon> Clean Tracks Status</MenuItem>
        </T>
        <Divider />
        <T title='Generate playlist from a backup'>
          <MenuItem onClick={goUpload}><ListItemIcon><FileUploadIcon /></ListItemIcon> Upload a Config</MenuItem>
        </T>
        <T title='Create a backup file'>
          <MenuItem onClick={goDownload}><ListItemIcon><FileDownloadIcon /></ListItemIcon> Download Your Config</MenuItem>
        </T>
        <Divider />
        <SpotifyLogout>Logout</SpotifyLogout>
      </Menu>
    </div>
  )
}

const T = (props) => {
  return (
    <Tooltip title={props.title} placement='right'>{props.children}</Tooltip>
  )
}