import { createTheme } from '@mui/material'

export default createTheme({
  grid: {
    playlist: {
      backgroundColor: '#202F2F',
      color: '#fff',
    },
    played: {
      backgroundColor: '#1E2323',
    },
    track: {
      backgroundColor: '#000000',
    },
    settings: {
      backgroundColor: '#202f2f',
    },
  },
  box: {
    primary: {
      backgroundColor: '#202F2F',
      color: '#fff',
    },
    played: {
      color: '#727373',
    },
    timed: {
      color: '#727373',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#f1f1f1',
      backgroundColor: '#e5e4e2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fffdd2',
    },
    background: {
      default: '#131c1c',
      paper: '#121412',
    },
    played: {
      main: '#BFC1C1',
      contrastText: '#E5E6E6',
    },
    accent: {
      backgroundColor: '#65CFE9',
      color: '#fff',
    },
    artist: {
      backgroundColor: 'transparent',
      color: '#537aa7',
    },
  },
  props: {
    MuiAppBar: {
      color: 'transparent',
    },
    MuiButton: {
      size: 'small',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiFab: {
      size: 'small',
    },
    MuiFormControl: {
      margin: 'dense',
      size: 'small',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiRadio: {
      size: 'small',
    },
    MuiSwitch: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
      size: 'small',
    },
  },
})