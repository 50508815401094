import { Link } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import { emptyPlaylist } from '../core/reducer/playlistReducer'
import styles from './flex.module.css'

export default function RemoveAllTracks() {
  const playerUpdater = usePlayerUpdaterContext()
  const navigate = useNavigate()

  const clearTheShitOutOfIt = () => {
    playerUpdater(emptyPlaylist())
  }

  return (
    <div className={styles.flexcontainer}>
      <div className={styles.row}>
        <div className={styles.flexitem}>
          <Link onClick={clearTheShitOutOfIt} href="/" color="inherit">
            Are you sure you want to wipe all tracks and settings?
          </Link>
        </div>
      </div>
    </div>
  )
}