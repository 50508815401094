import { Link } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlayerStateContext } from '../core/PlayerContext'
import styles from './flex.module.css'

export default function Download() {
  const playerContext = usePlayerStateContext()
  const navigate = useNavigate()

  const downloadSettings = () => {
    let myJson = JSON.stringify(playerContext)
    let element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(myJson))
    element.setAttribute('download', `musicstarter-${Date.now()}.json`)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  return (
    <div className={styles.flexcontainer}>
      <div className={styles.row}>
        <div className={styles.flexitem}>
          <Link onClick={downloadSettings} href="/" color="inherit">
            Click here to download a copy of your playlist settings.
          </Link>
        </div>
      </div>
    </div>
  )
}