import { Link } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import playlistReducer, { CLEAN } from '../core/reducer/playlistReducer'
import styles from './flex.module.css'

export default function CleanList() {
  const playerContext = usePlayerStateContext()
  const playerUpdate = usePlayerUpdaterContext()
  const navigate = useNavigate()

  const cleanThisShit = () => {
    playerUpdate(playlistReducer(playerContext, {type: CLEAN}))
    navigate(-1)
  }

  return (
    <div className={styles.flexcontainer}>
      <div className={styles.row}>
        <div className={styles.flexitem}>
          <Link onClick={cleanThisShit} href="/" color="inherit">
            Do you want to clean the music list status?
          </Link>
        </div>
      </div>
    </div>
  )
}