import { Toolbar } from '@mui/material'
import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useUserStateContext, useUserUpdaterContext } from '../core/ApplicationContext'
import userReducer, { KILLWITHFIRE, NAME } from '../spotify/reducer/UserReducer'
import SpotifyPlaylistSelector from '../spotify/SpotifyPlaylists'
import Controls from './Controls'
import Header from './Header'
import Playlist from './Playlist'
import RemoveAllTracks from './RemoveAllTracks'
import Track from './Track'
import Download from './DownloadList'
import Upload from './UploadList'
import CleanList from './CleanList'

export default function Player() {
  const user = useUserStateContext()
  const userUpdater = useUserUpdaterContext()
  const navigate = useNavigate()

  //TODO: Edit if Spotify quota is raised
  const userEnrolled = (response) => {
    if (response.status === 403) {
      throw new Error('USER_NOT_ENROLLED')
    }
    return response
  }

  const userIsPremium = (json) => {
    if (json.product === 'premium') {
      throw new Error('USER_NOT_PREMIUM')
    }
    return json
  }

  useEffect(() => {
    if (user.name == null) {
      fetch('https://api.spotify.com/v1/me', { method: 'GET', headers: { 'Authorization': `Bearer ${user.token}` } })
        .then(response => userEnrolled(response))
        .then(response => response.json())
        .then(json => userIsPremium(json))
        .then(json => userReducer(user, { type: NAME, name: json.display_name }))
        .then(reducedUser => userUpdater(reducedUser))
        .catch(e => {
          switch (e.message) {
            case 'USER_NOT_ENROLLED':
            case 'USER_NOT_PREMIUM':
              userUpdater(userReducer(user, { type: KILLWITHFIRE }))
              break
            default:
          }
        })
    }
  }, [])

  return (
    <div className='layout'>
      <div className='header'>
        <Header />
      </div>
      <div className='main'>
        <Routes>
          <Route path="/" element={<Playlist />} />
          <Route path="/track/:key" element={<Track />} />
          <Route path="/playlists" element={<SpotifyPlaylistSelector />} />
          <Route path="/removealltracks" element={<RemoveAllTracks />} />
          <Route path="/download" element={<Download />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/clean" element={<CleanList />} />
        </Routes>
      </div>
      <div className='footer'>
        <Controls />
        <Toolbar />
      </div>
    </div>
  )
}
