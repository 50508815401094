import React, { createContext, useState, useCallback, useContext } from 'react'
import { emptyUser } from '../spotify/reducer/UserReducer'

const UserContextState = createContext();
const UserContextUpdater = createContext();

const getStoredUser = () => {
  const localUser = localStorage.getItem('hockify_user')
  try {
    return localUser === null ? emptyUser() : JSON.parse(localUser)
  } catch (e) {
    console.error('Local user entity borked!')
    return emptyUser();
  }
}

export const useUserStateContext = () => {
  const context = useContext(UserContextState);
  if (context === undefined) {
    throw new Error("useUserState was used outside of its Provider");
  }
  return context;
};

export const useUserUpdaterContext = () => {
  const context = useContext(UserContextUpdater);
  if (context === undefined) {
    throw new Error("useUserUpdater was used outside of its Provider");
  }
  return context;
};

export const ApplicationContextProvider = ({ children }) => {
  const [user, setUser] = useState(getStoredUser())
  
  const updateUser = useCallback((user) => {
    localStorage.setItem('hockify_user', JSON.stringify(user))
    setUser(user);
  }, []);

  return (
    <UserContextState.Provider value={user}>
      <UserContextUpdater.Provider value={updateUser}>
        {children}
      </UserContextUpdater.Provider>
    </UserContextState.Provider>
  )
}