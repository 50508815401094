import * as React from 'react'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'

const blink = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`

const BlinkedBox = styled('div')({
  animation: `${blink} 1s linear infinite`,
})

export const Paused = ({children}) => {
  return <BlinkedBox>{children}</BlinkedBox>
}

export default Paused