
export const PLAY = 'PLAY'
export const PAUSE = 'PAUSE'
export const NEXT = 'NEXT'
export const PLAYBACK = 'PLAYBACK'
export const DONE = 'DONE'

export const emptyControl = () => {
  return { time: Date.now(), state: null, track: {}, playback: {}, device: {} }
}

export const controlReducer = (control, action) => {
  switch (action.type) {
    case PLAYBACK:
      return { ...control, time: Date.now(), state: PLAYBACK, playback: action.playback }
    case DONE:
      return { ...control, time: Date.now(), state: DONE }
    case PLAY:
      return { ...control, time: Date.now(), state: PLAY, track: action.track }
    default:
      return control
  }
}

export default controlReducer