import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import SpotifyLogout from '../spotify/SpotifyLogout'
import Playback from './Playback'
import BasicMenu from './BasicMenu'

export default function Header() {
  return (
    <Toolbar xs={12}>
      <BasicMenu />
      <Box sx={{ flexGrow: 1 }}>
        <Playback />
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SpotifyLogout />
      </Box>
    </Toolbar>
  )
}