import { Button, Container, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function StartPage() {
  const navigate = useNavigate()

  return (
    <Grid container direction='column' height='100vh' alignItems='center'>
      <Box sx={{ typography: 'h1' }}>Music Starter</Box>
      <Box mt={4}>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => navigate('spotify/login')} >
          Authorise via Spotify
        </Button>
      </Box>
      <Box mt={4}>
        You need to have a <a href='https://www.spotify.com/se/premium/'>Spotify Premium account</a>, and be in the Beta program for the Music Starter App to use this service.
      </Box>
    </Grid>
  )
}

export default StartPage