import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, MenuItem, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useUserStateContext, useUserUpdaterContext } from '../core/ApplicationContext'
import { emptyUser } from './reducer/UserReducer'
import LogoutIcon from '@mui/icons-material/Logout'

export default function SpotifyLogout({ children }) {
  const user = useUserStateContext()
  const userUpdater = useUserUpdaterContext()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handlelogout = () => {
    window.location.href = 'https://spotify.com/logout'
  }

  const destroyUser = (event) => {
    const spotify = window.open('https://spotify.com/logout', 'event', 'width=900,height=900,scrollbars=no,resizable=yes')
    if (!spotify) {
      setOpen(true)
    } else {
      spotify.blur()
      setTimeout(() => { spotify.close() }, 2000)
      userUpdater(emptyUser())
    }
  }

  useEffect(() => {
    if (user.logout == true) {
      console.log('Logging you out')
      destroyUser()
    }
  }, [user])

  return (
    <>
      <Tooltip title='Logout from Spotify and destroy this playlist'>
        <MenuItem onClick={destroyUser} color='inherit'><ListItemIcon><LogoutIcon /></ListItemIcon>{children}</MenuItem>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Then do it Yourself!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            It seems like your browser blocked a popup for the logout and your Spotify account couldn't be completely cleared.
            If you want to clear your Spotify context. You can choose that below!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlelogout} autoFocus>Please Log me out!</Button>
          <Button onClick={handleClose}>Ok, whatever</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}