import React, { useRef, useState } from 'react'
import { Avatar, Box, Button, CardMedia, Container, Grid, TextField, ThemeProvider, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserStateContext } from '../core/ApplicationContext'
import { useControlStateContext, useControlUpdaterContext } from '../core/ControlContext'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import AppTheme from '../core/AppTheme'
import controlReducer, { PLAY } from '../core/reducer/controlReducer'
import playlistReducer, { DELETE, UPDATE } from '../core/reducer/playlistReducer'

export const calculateTime = (ms, res) => {
  const minutes = Math.floor(ms / 60000)
  const seconds = Math.floor((ms % 60000) / 1000)
  const millis = (ms % 1000).toFixed(0)
  const time = seconds == 60 ? (minutes + 1) + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  return res === 'ms' ? `${time}.${millis.padStart(3, '0')}` : time
}

export default function Track() {

  const user = useUserStateContext()
  const control = useControlStateContext()
  const controlUpdate = useControlUpdaterContext()
  const playlist = usePlayerStateContext()
  const playlistUpdate = usePlayerUpdaterContext()
  const params = useParams()
  const navigate = useNavigate()
  const [track, setTrack] = useState(playlist.tracks[params.key])
  const inputRef = useRef({ value: track.startAt })
  const [msError, setMsError] = useState({ error: false, text: '' })

  const testPlay = () => {
    const playThis = { ...track, startAt: inputRef.current.value }
    controlUpdate(controlReducer(control, { type: PLAY, track: playThis }))
  }

  const save = () => {
    const saveThis = { ...track, startAt: inputRef.current.value }
    playlistUpdate(playlistReducer(playlist, { type: UPDATE, track: saveThis, key: params.key }))
    navigate(-1)
  }

  const cancel = () => {
    navigate(-1)
  }

  const remove = () => {
    playlistUpdate(playlistReducer(playlist, { type: DELETE, key: params.key }))
    navigate(-1)
  }

  const validateTime = (event) => {
    if (!/^[0-9]+$/.test(event.target.value)) {
      setMsError({ error: true, text: 'This aint a number!' })
    } else {
      setMsError({ error: false, text: '' })
    }
  }

  return (
    <ThemeProvider theme={AppTheme}>
      <Container maxWidth='lg' disableGutters>
        <Grid container spacing={2} p={2}
          style={AppTheme.grid.track}
          direction='row'
          justifyContent='space-between'
          alignItems='top' >
          <Grid item xs={8} md={10}>
            <Avatar alt={`${track.artist} - ${track.name}`} src={track.image} sx={{ width: 64, height: 64 }} variant='square' />
          </Grid>
          <Grid item xs={4} md={2}>
            <Tooltip title={`Open ${track.name} in Spotify`}>
              <Button href={track.externalUrl}>
                <CardMedia component='img'
                  image='/spotify.png'
                  alt={`Open ${track.name} at Spotify`} />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item flexGrow={1} >
            <Box sx={{ typography: 'h6' }} >
              {track.name}
            </Box>
            <Box item sx={{ typography: 'subtitle' }} noWrap style={AppTheme.palette.artist}>
              {track.artists}
            </Box>
            <Box item sx={{ typography: 'subtitle' }} noWrap style={AppTheme.palette.artist}>
              {track.album}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth='lg' disableGutters >
        <Grid container
          spacing={2} p={2} mt={2}
          style={AppTheme.grid.settings}
          direction='row'
          justifyContent='space-around'
          alignItems='top' >
          <Grid item
            xs={12}
            minHeight='100px'>
            <Box display='flex'
              direction='row'
              alignItems='baseline' >
              <TextField id='startAt'
                label='Start at (ms)'
                type='number'
                variant='outlined'
                inputRef={inputRef}
                defaultValue={track.startAt}
                onChange={validateTime}
                error={msError.error}
                helperText={msError.text}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <Box p={2}
                sx={{ typography: 'body1' }}>
                {calculateTime(inputRef.current.value, 'ms')}
              </Box>
              <Box p={2}
                sx={{ typography: 'body1' }}>
                <Button variant='outlined' onClick={testPlay} disabled={msError.error}>Try!</Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Button variant='outlined' onClick={remove} disabled={msError.error}>Remove Song</Button>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex'
              justifyContent='flex-end'
              direction='row' >
              <Button variant='outlined' onClick={save} disabled={msError.error}>Save</Button>
              <Button variant='outlined' onClick={cancel}>Cancel</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider >
  )
}
