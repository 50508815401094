import React from 'react'

const clientId = 'a286ce8b8d3b4f0c9575651d30cee95c'
const scope = 'user-modify-playback-state user-read-playback-state playlist-read-private'
let redirectUri = ''
const host = window.location.hostname
switch (host) {
  case 'localhost':
    redirectUri = 'http://localhost:3000/spotify/callback'
    break
  case 'player.primenta.se':
    redirectUri = 'https://player.primenta.se/spotify/callback'
    break
  default:
}

const state = Math.random().toString(36).slice(-5)

export default function SpotifyLogin() {
  let url = 'https://accounts.spotify.com/authorize?response_type=token'
  url += '&client_id=' + encodeURIComponent(clientId)
  url += '&scope=' + encodeURIComponent(scope)
  url += '&redirect_uri=' + encodeURIComponent(redirectUri)
  url += '&state=' + encodeURIComponent(state)
  window.location.href = url

  return (
    <div>Waiting for spotify</div>
  )
}