export const APPEND = 'APPEND'
export const PLAYED = 'PLAYED'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const CLEAN = 'CLEAN'
export const APPEND_PLAYPLACK = 'APPEND_PLAYPLACK'

export const emptyTrack = () => {
  return { id: null, artists: null, name: null, album: null, externalUrl: null, uri: null, image: null, position: 0, startAt: 0, duration: 0, isPlayed: false }
}

export const emptyPlaylist = () => {
  return { id: Date.now(), name: null, tracks: [] }
}

export const playlistReducer = (playlist, action) => {
  switch (action.type) {
    case APPEND:
      const append = action.tracks?.items?.map(function (item) {
        const artists = item.track.artists.reduce((name, artist) => name === '' ? artist.name : `${name}/${artist.name}`, '')
        const trackNumber = item.track.track_number - 1
        const image = item.track?.album?.images[item.track.album.images.length - 1].url
        return {
          id: item.track.album.id,
          artists: artists,
          name: item.track.name,
          album: item.track.album?.name,
          externalUrl: item.track.external_urls?.spotify,
          uri: item.track.album.uri,
          image: image,
          position: trackNumber,
          startAt: 0,
          duration: item.track.duration_ms,
          isPlayed: false
        }
      })
      const concat = [...playlist.tracks, ...append]
      return { ...playlist, tracks: concat }
    case PLAYED:
      const imute = JSON.parse(JSON.stringify(playlist.tracks))
      imute[action.key].isPlayed = true
      return { ...playlist, tracks: imute }
    case UPDATE:
      const update = JSON.parse(JSON.stringify(playlist.tracks))
      update[action.key] = action.track
      return { ...playlist, tracks: update }
    case DELETE:
      const delet = JSON.parse(JSON.stringify(playlist.tracks))
      delet.splice(action.key, 1)
      return { ...playlist, tracks: delet }
    case CLEAN:
      const clean = playlist.tracks.map(item => { return { ...item, isPlayed: false } })
      return { ...playlist, tracks: clean }
    case APPEND_PLAYPLACK:
      const item = action.item
      const pbArtists = item.artists.reduce((name, artist) => name === '' ? artist.name : `${name}/${artist.name}`, '')
      const pbImage = item.album?.images[item.album.images.length - 1].url
      const pbTrackNumber = item.track_number - 1
      const appendMe = {
        id: item.album.id,
        artists: pbArtists,
        name: item.name,
        album: item.album?.name,
        externalUrl: item.external_urls?.spotify,
        uri: item.album.uri,
        image: pbImage,
        position: pbTrackNumber,
        startAt: 0,
        duration: item.duration_ms,
        isPlayed: false
      }
      const pbConcat = [appendMe, ...playlist.tracks]
      return { ...playlist, tracks: pbConcat }
    default:
      return playlist
  }
}

export default playlistReducer