import { IconButton, Box, Grid, Container, Avatar, CardActionArea } from '@mui/material'
import React, { useRef } from 'react'
import { useControlStateContext, useControlUpdaterContext } from '../core/ControlContext'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import controlReducer, { PLAY } from '../core/reducer/controlReducer'
import SettingsIcon from '@mui/icons-material/Settings'
import { useNavigate } from 'react-router-dom'
import AppTheme from '../core/AppTheme'
import playlistReducer, { PLAYED } from '../core/reducer/playlistReducer'
import { calculateTime } from './Track'

export default function PlaylistManager() {
  const playlist = usePlayerStateContext()
  const playlistUpdater = usePlayerUpdaterContext()
  const control = useControlStateContext()
  const controlUpdate = useControlUpdaterContext()
  const navigate = useNavigate()
  const rippleRef = useRef(null)

  const trackClick = (event, track, key) => {
    controlUpdate(controlReducer(control, { type: PLAY, track }))
    playlistUpdater(playlistReducer(playlist, { type: PLAYED, key: key }))
  }

  const settingsClick = (key) => {
    navigate(`/track/${key}`)
  }

  return (
    <Container maxWidth='lg' disableGutters>
      {playlist.tracks &&
        playlist.tracks.map((track, key) =>
          <CardActionArea key={key}>
            <Grid pt={1} key={key}>
              <Grid container style={track.isPlayed ? AppTheme.grid.played : AppTheme.grid.playlist} flexGrow={1}
                direction='row'
                justifyContent='space-between'
                alignItems='baseline'
                direction='row-reverse'>
                <Grid item xs={1} margin='auto' onClick={() => settingsClick(key) } align='right' >
                  <SettingsIcon color='primary' fontSize='large' sx={{ margin:'1vh' }} />
                </Grid>
                <Grid item
                  xs={9.5}
                  flexGrow={1}
                  onClick={(event) => trackClick(event, track, key)}
                  sx={{ overflow: 'hidden' }}
                  p={1} >
                  <Box sx={{ typography: 'h6', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    style={track.isPlayed ? AppTheme.box.played : AppTheme.box.primary} >
                    {track.name}
                  </Box>
                  <Box item sx={{ typography: 'body1', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    noWrap
                    style={AppTheme.palette.artist} >
                    {track.artists}
                  </Box>
                  <Box item sx={{ typography: 'body2' }} noWrap style={AppTheme.box.timed}>
                    {calculateTime(track.duration)} {track.startAt || track.startAt !== 0 ? `(start at ${calculateTime(track.startAt, 'ms')})` : ''}
                  </Box>
                </Grid>
                <Grid item xs='auto' margin='auto' sx={{ display: { xs: 'none', sm: 'block' } }} >
                  <Avatar src={track.image} sx={{ width: 64, height: 64, }} variant='square' />
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        )}
    </Container>
  )
}