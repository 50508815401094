import { Link } from '@mui/material'
import React from 'react'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import styles from './playlist.module.css'
import PlaylistManager from './PlaylistManager'

export default function Playlist() {
  const playlist = usePlayerStateContext()
  const playlistUpdater = usePlayerUpdaterContext()

  return (
    <div>
      {playlist.tracks?.length > 0 ? <PlaylistManager /> : <NoPlaylist />}
    </div>
  )
}

function NoPlaylist() {

  return (
    <div className={styles.flexcontainer}>
      <div className={styles.row}>
        <div className={styles.flexitem}>
          <Link href="/playlists" color="inherit">
            You need to add tracks from a playlist
          </Link>
          <div>or</div>
          <Link href="/upload" color="inherit">
            upload a config
          </Link>
        </div>
      </div>
    </div>
  )
}