import { Alert, Fab, Snackbar, Tooltip } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@mui/system'
import { useControlStateContext, useControlUpdaterContext } from '../core/ControlContext'
import { useUserStateContext } from '../core/ApplicationContext'
import controlReducer, { DONE, PLAY, PLAYBACK } from '../core/reducer/controlReducer'
import { useNavigate } from 'react-router-dom'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import playlistReducer, { PLAYED } from '../core/reducer/playlistReducer'

export default function Controls() {
  const user = useUserStateContext()
  const control = useControlStateContext()
  const controlUpdate = useControlUpdaterContext()
  const playlist = usePlayerStateContext()
  const playlistUpdate = usePlayerUpdaterContext()
  const [snackbar, setSnackbar] = useState({ open: false, type: 'info', message: '' })
  const navigate = useNavigate()
  const NO_ACTIVE_DEV_WARN = { open: true, type: 'warning', message: 'No Spotify device found. Play a song in the Spotify app to connect!' }
  const activateModulus = useRef(0)
  const keepPlayerActiveSemaphore = useRef(false)

  // Safety harness if control becomes bork
  if (control.track === undefined) {
    controlUpdate({ ...control, track: {} }, { type: DONE })
  }

  const waitUntil = (condition) => {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (!condition()) {
          return
        }
        clearInterval(interval)
        resolve()
      }, 50)
    })
  }

  const makeSureDeviceKeepsActiveWhenPaused = (json) => {
    activateModulus.current = activateModulus.current + 1
    if (!json.is_playing && activateModulus.current % 40 == 0) {
      keepPlayerActiveSemaphore.current = true
      var time = json.progress_ms
      fetch(`https://api.spotify.com/v1/me/player/seek?position_ms=${time}`,
        {
          timeout: 1000,
          method: 'PUT',
          headers: { 'Authorization': `Bearer ${user.token}` }
        })
        .catch(() => console.log('pos fix failed'))
        .finally(() => keepPlayerActiveSemaphore.current = false)
    }
  }

  const playback = () => {
    if (user.name != null) {
      fetch('https://api.spotify.com/v1/me/player', { method: 'GET', headers: { 'Authorization': `Bearer ${user.token}` } })
        .then(response => response.statusText != 'No Content' ? response.json() : {})
        .then(json => {
          if (json.error?.message === 'The access token expired') {
            navigate('/spotify/login')
          } else {
            return json
          }
        })
        .then(json => {
          if (json.device === undefined) {
            throw new Error('NO_ACTIVE_DEVICE')
          }
          return json
        })
        .then(json => {
            controlUpdate(controlReducer(control, { type: PLAYBACK, playback: json }))
            makeSureDeviceKeepsActiveWhenPaused(json)
         })
        .catch(e => {
          if (e.message === 'NO_ACTIVE_DEVICE') {
            setSnackbar(NO_ACTIVE_DEV_WARN)
          }
        })
    }
  }

  const playTopMostUnplayed = () => {
    const index = playlist.tracks.findIndex(track => !track.isPlayed)
    const playThis = playlist.tracks[index]
    if (index > -1) {
      controlUpdate(controlReducer(control, { type: PLAY, track: playThis }))
      playlistUpdate(playlistReducer(playlist, { type: PLAYED, key: index }))
    } else {
      setSnackbar({ open: true, type: 'warning', message: 'All tracks are played!' })
    }
  }

  const play = (uri, pos, startAt) => {
    waitUntil(() => keepPlayerActiveSemaphore.current == false)
      .then(() => {
        fetch('https://api.spotify.com/v1/me/player/play',
          {
            timeout: 3000,
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({
              context_uri: uri,
              offset: { position: pos },
              position_ms: startAt
            })
          }
        )
          .then(response => response.statusText != 'No Content' ? response.json() : {})
          .then(json => {
            if (json?.error?.reason === 'NO_ACTIVE_DEVICE') {
              throw new Error('NO_ACTIVE_DEVICE')
            } else {
              return json
            }
          })
          .then(() => controlUpdate(controlReducer(control, { type: DONE })))
          .catch(e => {
            if (e.message === 'NO_ACTIVE_DEVICE') {
              setSnackbar(NO_ACTIVE_DEV_WARN)
            } else {
              console.log(e)
            }
          })
          .finally(() => playback())
      })
  }

  const pauseMe = () => {
    if (user.name != null) {
      fetch('https://api.spotify.com/v1/me/player/pause', { method: 'PUT', headers: { 'Authorization': `Bearer ${user.token}` } })
        .then(response => {
          if (response.ok) {
            playback()
          }
        })
        .catch(e => console.log(e))
    }
  }

  const playMe = () => {
    if (user.name != null && control.playback?.device?.id) {
      play(control.playback.item.album.uri, control.playback.item.track_number - 1, control.playback.progress_ms)
    }
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  useEffect(() => {
    const interval = setInterval(() => { playback() }, 2000)
    return () => clearInterval(interval)
  })

  useEffect(() => {
    if (control.state === PLAY) {
      play(control.track.uri, control.track.position, control.track.startAt)
    }
  }, [control])

  return (
    <Box sx={{
      display: 'flex',
      height: '8vh',
    }}>
      <Box sx={{ margin: 'auto' }}>
        <Tooltip title='Play/Pause active song'>
          <Fab aria-label='Play' sx={{ m: 1 }} onClick={() => control.playback?.is_playing ? pauseMe() : playMe()}>
            {control.playback?.is_playing ? <PauseIcon /> : <PlayArrowIcon />}
          </Fab>
        </Tooltip>
        <Tooltip title='Play next top most non played song'>
          <Fab color='secondary' aria-label='Pause' sx={{ m: 1 }} onClick={() => playTopMostUnplayed()}>
            <SkipNextIcon />
          </Fab>
        </Tooltip>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbar.type} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
