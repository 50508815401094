export const NAME = 'NAME'
export const TOKEN = 'TOKEN'
export const EXPIRES = 'EXPIRES'
export const CALLBACK = 'CB'
export const KILLWITHFIRE = 'KILL'

export const emptyUser = () => {
  return { name: null, token: null, expires: null, logout: false }
}

export const userReducer = (user, action) => {
  switch (action.type) {
    case NAME:
      return { ...user, name: action.name }
    case TOKEN:
      return { ...user, token: action.token }
    case EXPIRES:
      return { ...user, expires: action.expires }
    case CALLBACK:
      return { ...user, expires: action.expires, token: action.token }
    case KILLWITHFIRE:
      return { ...user, logout: true}
    default:
      return user
  }
}

export default userReducer