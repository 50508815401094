import React, { useState } from 'react'
import { useControlStateContext } from '../core/ControlContext'
import { useUserStateContext } from '../core/ApplicationContext'
import { Avatar, Box, ButtonBase, ListItemIcon, Menu, MenuItem } from '@mui/material'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PlaybackPaused from './PlaybackPaused'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import playlistReducer, { APPEND_PLAYPLACK } from '../core/reducer/playlistReducer'

const Content = (props) => {
  const playlist = usePlayerStateContext()
  const playlistUpdater = usePlayerUpdaterContext()
  const item = props.control?.playback?.item
  const progress = props.control?.playback?.progress_ms
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const addToList = () => {
    playlistUpdater(playlistReducer(playlist, {type: APPEND_PLAYPLACK, item: item}))
    setAnchorEl(null)
  }

  const getTime = (millis) => {
    const minutes = Math.floor(millis / 60000)
    const seconds = ((millis % 60000) / 1000).toFixed(0)
    return seconds == 60 ? (minutes + 1) + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }

  return (
    <>
      <ButtonBase onContextMenu={handleClick} onClick={handleClick}>
        <Box display='flex' justifyContent='flex-start' direction='row' alignItems='center' >
          <Avatar src={item.album?.images[item.album.images.length - 1].url} sx={{ width: 48, height: 48, display: { xs: 'none', sm: 'block' } }} variant='square' />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} m={2}>
            {item.artists[0].name}
          </Box>
          <Box>
            {`${item?.name.trim()} - ${getTime(progress)}`}
          </Box>
        </Box >
      </ButtonBase >
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={addToList}><ListItemIcon><PlaylistAddIcon /></ListItemIcon> Add this song to the list</MenuItem>
      </Menu>
    </>
  )
}

export default function SpotifyUser() {
  const control = useControlStateContext()
  const user = useUserStateContext()

  return (
    <>
      <Box sx={{ typography: 'body1' }} >
        {control.playback?.is_playing && control.playback?.item?.name !== undefined ? <Content control={control} /> :
          control.playback?.item?.name === undefined ? '' : <PlaybackPaused><Content control={control} /></PlaybackPaused>}
      </Box>
    </>
  )
}
