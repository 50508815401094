import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserUpdaterContext } from '../core/ApplicationContext'
import userReducer, {emptyUser, CALLBACK} from './reducer/UserReducer'

function Callback() {
  const navigate = useNavigate();
  const userUpdater = useUserUpdaterContext()
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash)
    const expiry = Date.now() + (params.get('expires_in') * 1000)
    const user = userReducer(emptyUser(), {type: CALLBACK, token: params.get('#access_token'), expires: expiry})
    userUpdater(user)
    navigate('/', { replace: true })
  }, [])
  
  return (
    <div>
      Callback...
    </div>
  )
}

export default Callback;
