import { Button, Link } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlayerStateContext, usePlayerUpdaterContext } from '../core/PlayerContext'
import styles from './flex.module.css'

export default function Upload() {
  const fileInput = React.useRef()
  const playerUpdate = usePlayerUpdaterContext()
  const navigate = useNavigate()

  const testJson = (text) => {
    if (typeof text !== "string") {
      return false
    }
    try {
      var json = JSON.parse(text)
      return (typeof json === 'object')
    }
    catch (error) {
      console.log('Upload requires a valid JSON. Dude...')
      return false
    }
  }

  const parseFile = (event) => {
    const reader = new FileReader()
    reader.addEventListener('load', (ev) => {
      const result = ev.target.result
      if (testJson(result)) {
        playerUpdate(JSON.parse(result))
        navigate('/')
      }
    })
    reader.readAsText(event.target.files[0])
  }

  return (
    <div className={styles.flexcontainer}>
      <div className={styles.row}>
        <div className={styles.flexitem}>
          <div>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => fileInput.current.click()} >
              upload file
            </Button>

            <input
              ref={fileInput}
              type='file'
              accept='.json'
              onClick={(event) => { event.target.value = null }}
              onChange={parseFile}
              style={{ display: 'none' }} />
          </div>
        </div>
      </div>
    </div>
  )
}