import React, { createContext, useState, useCallback, useContext } from 'react'
import { emptyControl } from './reducer/controlReducer'

const LOCAL_ID = 'hockify_control'
const controlContextState = createContext();
const controlContextUpdater = createContext();

export const useControlStateContext = () => {
  const context = useContext(controlContextState);
  if (context === undefined) {
    throw new Error(`${LOCAL_ID} Context was used outside of its Provider`);
  }
  return context;
};

export const useControlUpdaterContext = () => {
  const context = useContext(controlContextUpdater);
  if (context === undefined) {
    throw new Error(`${LOCAL_ID} Updater Context was used outside of its Provider`);
  }
  return context;
};

const getEmptyStoreObj = () => {
  return emptyControl()
}

const getStoredObj = () => {
  const obj = localStorage.getItem(LOCAL_ID)
  try {
    return obj === null ? getEmptyStoreObj() : JSON.parse(obj)
  } catch (e) {
    console.error(`Local ${LOCAL_ID} entity borked!`)
    return getEmptyStoreObj
  }
}

export const ControlContextProvider = ({ children }) => {

  const [obj, setObj] = useState(getStoredObj())
  const updateObj = useCallback((obj) => {
    localStorage.setItem(LOCAL_ID, JSON.stringify(obj))
    setObj(obj);
  }, []);

  return (
    <controlContextState.Provider value={obj}>
      <controlContextUpdater.Provider value={updateObj}>
        {children}
      </controlContextUpdater.Provider>
    </controlContextState.Provider>
  )
}