import React, { createContext, useState, useCallback, useContext } from 'react'
import { emptyPlaylist } from './reducer/playlistReducer'

const LOCAL_ID = 'hockify_player'
const playerContextState = createContext();
const playerContextUpdater = createContext();

export const usePlayerStateContext = () => {
  const context = useContext(playerContextState);
  if (context === undefined) {
    throw new Error(`${LOCAL_ID} Context was used outside of its Provider`);
  }
  return context;
};

export const usePlayerUpdaterContext = () => {
  const context = useContext(playerContextUpdater);
  if (context === undefined) {
    throw new Error(`${LOCAL_ID} Updater Context was used outside of its Provider`);
  }
  return context;
};

const getEmptyStoreObj = () => {
  return emptyPlaylist()
}

const getStoredObj = () => {
  const obj = localStorage.getItem(LOCAL_ID)
  try {
    return obj === null ? getEmptyStoreObj() : JSON.parse(obj)
  } catch (e) {
    console.error(`Local ${LOCAL_ID} entity borked!`)
    return getEmptyStoreObj
  }
}

export const PlayerContextProvider = ({ children }) => {

  const [obj, setObj] = useState(getStoredObj())
  const updateObj = useCallback((obj) => {
    localStorage.setItem(LOCAL_ID, JSON.stringify(obj))
    setObj(obj)
  }, []);

  return (
    <playerContextState.Provider value={obj}>
      <playerContextUpdater.Provider value={updateObj}>
        {children}
      </playerContextUpdater.Provider>
    </playerContextState.Provider>
  )
}